import React, { memo, useContext } from 'react'
import { ButtonLink } from './Button'
import ThemeContext from '../context/ThemeContext'

const SocialLink = ({ socialMedia, link, title = '', ariaLabel, clickHandler }) => {
  const theme = useContext(ThemeContext)
  return (
    <ButtonLink
      simpleLink
      scale={1.5}
      href={link}
      title={title}
      onClick={clickHandler}
      rel="noopener noreferrer"
      aria-label={ariaLabel}
      style={{
        filter: theme.dark ? 'grayscale(60%)' : '',
      }}
    >
      <img src={socialMedia.icon} className="footer-img" alt={socialMedia.alt} />
    </ButtonLink>
  )
}

export default memo(SocialLink)
