import React, { memo } from 'react'
import socialProfiles, { socialSite } from '../../data/socialProfiles'
import SocialLink from './SocialLink'
import config from '../../data/SiteConfig'
import { ButtonLink } from './Button'

export const getShareLink = ({ slug, title }) => {
  const baseSlugUrl = `${config.siteUrl}/${slug}`.replace(/\/$/, '')
  return {
    baseSlugUrl,
    fbShareLink: `https://facebook.com/sharer/sharer.php?u=${baseSlugUrl}`,
    twShareLink: `http://twitter.com/share?text="${title}"-&url=${baseSlugUrl}&via=ashmeetsehgal`,
    ldShareLink: `https://www.linkedin.com/sharing/share-offsite/?url=${baseSlugUrl}`,
    rdShareLink: `http://www.reddit.com/submit?url=${baseSlugUrl}&title=${title}`,
  }
}

export const SocialShareLink = ({ baseSlugUrl, title }) => {
  const onShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title,
          url: baseSlugUrl,
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch(console.error)
    } else {
      // TODO: need to open a dialogue having all share button
      window
        .open(
          `http://twitter.com/share?text="${title}"-&url=${baseSlugUrl}&via=ashmeetsehgal`,
          '_blank',
        )
        .focus()
    }
  }

  return (
    <ButtonLink
      simpleLink
      className="twitter-link"
      onClick={onShareClick}
      target="_blank"
      rel="noopener noreferrer"
      title="share"
    >
      Share
    </ButtonLink>
  )
}

const SocialShareSection = ({ slug, title, viewpoint = 'mobile' }) => {
  const { fbShareLink, twShareLink, ldShareLink, rdShareLink, baseSlugUrl } = getShareLink({
    slug,
    title,
  })

  return (
    <div className={`blog-social-share ${viewpoint}`}>
      <h4>
        <SocialShareLink baseSlugUrl={baseSlugUrl} title={title} />
      </h4>
      <SocialLink
        ariaLabel="share on facebook"
        title="share on facebook"
        link={fbShareLink}
        socialMedia={socialProfiles[socialSite.FACEBOOK]}
      />
      <SocialLink
        ariaLabel="share on twitter"
        title="share on twitter"
        link={twShareLink}
        socialMedia={socialProfiles[socialSite.TWITTER]}
      />
      <SocialLink
        ariaLabel="share on linkedin"
        title="share on linkedin"
        link={ldShareLink}
        socialMedia={socialProfiles[socialSite.LINKEDIN]}
      />
      <SocialLink
        ariaLabel="share on reddit"
        title="share on reddit"
        link={rdShareLink}
        socialMedia={socialProfiles[socialSite.REDDIT]}
      />
      <SocialLink
        ariaLabel="copy link"
        title="copy link"
        socialMedia={socialProfiles.shareLink}
        clickHandler={() => {
          navigator.clipboard.writeText(baseSlugUrl)
        }}
      />
    </div>
  )
}

export default memo(SocialShareSection)
