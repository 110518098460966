import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'
import React, { useContext, useState, lazy, Suspense } from 'react'
// import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import config from '../../data/SiteConfig'
import { GatsbyLink } from '../components/Button'
import SEO from '../components/SEO'
import SocialShareSection, { SocialShareLink, getShareLink } from '../components/SocialShare'
import ThemeContext from '../context/ThemeContext'
// import useOnScreen from '../hooks/useOnScreen'
import { formatDate } from '../utils/global'

const UserInfoComponent = lazy(() => import('../components/UserInfo'))
const Layout = lazy(() => import('../layout'))
const Suggested = lazy(() => import('../components/Suggested'))
const ScrollProgress = lazy(() => import('../components/ScrollProgress'))
const PostTags = lazy(() => import('../components/PostTags'))
const Accordion = lazy(() => import('../components/Accordion'))
const StaticBreadcrumb = lazy(() => import('../components/StaticBreadcrumb'))

const Post = ({ pageContext, data, location }) => {
  const { slug, previous, next } = pageContext
  const postNode = data.markdownRemark
  const post = postNode.frontmatter
  const [expanded, setExpanded] = useState(true)
  const context = useContext(ThemeContext)
  const { dark } = context

  // const ref = useRef()
  // const onScreen = useOnScreen(ref, '100px')

  const thumbnail = getImage(post?.thumbnail)
  const seoImage = thumbnail?.images?.fallback?.src
  if (!post.id) {
    post.id = slug
  }

  if (!post.category_id) {
    post.category_id = config.postDefaultCategoryID
  }

  // const generalPostUrl = `${config.siteUrl + slug}`
  const postTitle = encodeURIComponent(post.title)

  const timeToRead = postNode?.timeToRead ? `${postNode?.timeToRead} min read` : ''
  const toc = postNode?.tableOfContents
  const date = formatDate(post.date)

  // const disqusConfig = {
  //   url: generalPostUrl,
  //   identifier: post.id,
  //   title: post.title,
  // }

  // const applyCustomFonts = (htmlString) => {
  //   if (typeof window !== 'undefined') {
  //     const parser = new DOMParser()
  //     const doc = parser.parseFromString(htmlString, 'text/html')

  //     // Modify the document's styles as needed
  //     const body = doc.querySelector('body')
  //     body.style.fontFamily = "'Source Code', sans-serif"

  //     return doc.documentElement.outerHTML
  //   }
  // }

  // const modifiedHtmlContent = applyCustomFonts(postNode.html)

  return (
    <Suspense fallback={<span />}>
      <Layout
        title={`${post.title} – ${config.siteTitle}`}
        seoImage={seoImage}
        seoTitle={`${post.title} – ${config.siteTitle}`}
        seoDescription={`${post.seoDescription || postNode.description} – ${config.siteTitle}`}
        postPath={slug}
        postNode={postNode}
        postSEO={true}
        isThemeDark={dark}
        date={date}
        href={location.href}
      >
        <div className="container">
          <Suspense fallback={<span />}>
            <StaticBreadcrumb postTitle={post.title} />
          </Suspense>
        </div>
        <article className="single container">
          <header className={`single-header ${!thumbnail ? 'no-thumbnail' : ''}`}>
            <div className="flex">
              <h1>{post.title}</h1>
              <i style={{ textAlign: 'right', marginRight: '1rem' }}>{post.description}</i>
              <div className="post-meta">
                <GatsbyLink title="article author" to="/about" aria-label="article author">
                  <StaticImage
                    src="../../static/logos/logo-192.webp"
                    className="avatar-small"
                    alt="Ashmeet Sehgal"
                    title="Ashmeet Sehgal"
                    placeholder="blurred"
                    style={{
                      filter: dark ? 'grayscale(100%)' : '',
                    }}
                  />
                </GatsbyLink>
                <time className="date">{date}</time>/ &nbsp;
                {postNode?.timeToRead && <time className="datetime">{timeToRead}</time>}
                &nbsp; /
                <SocialShareLink
                  title={post.title}
                  baseSlugUrl={getShareLink({ slug, title: post.title }).baseSlugUrl}
                />
                {/* &nbsp; / &nbsp;
              <SocialShareSection viewpoint="mobile" title={encodeURIComponent(post.title)} slug={slug} /> */}
              </div>
              <Suspense fallback={<span />}>
                <PostTags tags={post.tags} />
              </Suspense>
            </div>
            {thumbnail && (
              <span className="hideMobile">
                <GatsbyImage image={thumbnail} alt={post.title} />
              </span>
            )}
          </header>
          {toc && (
            <Suspense fallback={<span />}>
              <Accordion
                header="Table of content"
                panelId={true}
                expanded={expanded}
                setExpanded={setExpanded}
              >
                <p dangerouslySetInnerHTML={{ __html: toc }} />
              </Accordion>
            </Suspense>
          )}

          <div>
            <div className="post" dangerouslySetInnerHTML={{ __html: postNode.html }} />
          </div>
          <Suspense fallback={<span />}>
            <ScrollProgress />
          </Suspense>
          <SocialShareSection viewpoint="desktop" title={postTitle} slug={slug} />
        </article>
        <div>
          <>
            <Suspense fallback={<span />}>
              <UserInfoComponent />
            </Suspense>

            {(previous || next) && (
              <Suspense fallback={<span />}>
                <Suggested previous={previous} next={next} />{' '}
              </Suspense>
            )}
          </>
        </div>
        {/* <div ref={ref}>
        {onScreen && (
          <div className="container">
            <CommentCount config={disqusConfig} />
            <Disqus config={disqusConfig} />
          </div>
        )}
      </div> */}
      </Layout>
    </Suspense>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      tableOfContents(maxDepth: 5)
      frontmatter {
        title
        description
        seoDescription
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        date
        categories
        tags
        template
      }
    }
  }
`
